import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { DateTime } from "luxon";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date, format) => {
  return DateTime.fromFormat(date, "dd-MM-yyyy").toFormat(format);
};
export const getTimeFromIsoString = (isoDate) => {
  return DateTime.fromISO(isoDate, { zone: "utc" })
    .setZone("Asia/Kolkata")
    .toFormat("h:mma")
    .toUpperCase();
};

export const getRelativeTimeDifference = (isoDate) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(isoDate, { zone: "utc" });

  return date.toRelative({ base: now, style: "narrow" });
};

export const toShortForm = (number) => {
  // Shorten the number to K, M, B, T
  if (!number) return "0";

  try {
    if (number >= 1000000000000) {
      return (number / 1000000000000).toFixed(2) + " T";
    } else if (number >= 1000000000) {
      return (number / 1000000000).toFixed(2) + " B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + " M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + " K";
    } else {
      return Number(number).toFixed(2).toString();
    }
  } catch (error) {
    console.log("Error in toShortForm", error);
    return number.toString();
  }
};

export const formateValue = (value) => {
  if (value === 0) {
    return value;
  } else {
    return Number(value).toFixed(2);
  }
};

export const getMaxValue = (processorRate, scraperRate) =>
  Math.max(processorRate, scraperRate);
