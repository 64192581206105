import { create } from "zustand";

const defaultKeys = {
  "frame-downloader": "frame-download",
  "image-classification": "classification",
  "threat-level": "threat-level",
  deepfake: "deepfake",
};

function replaceKeyInString(inputString) {
  for (const key in defaultKeys) {
    switch (true) {
      case inputString.includes(key):
        inputString = defaultKeys[key];
        break;
      default:
        break;
    }
  }
  return inputString;
}

const usePostProcessingStore = create((set) => ({
  postProcessingData: { graph_data: {}, last_batch: {} },
  setPostProcessingData: (data) => set({ postProcessingData: data }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  postProcessingInstanceData: {},
  setPostProcessingInstanceData: (data) => {
    set({ postProcessingInstanceData: data });
  },

  postProcessingInstanceLoading: false,
  setPostProcessingInstanceLoading: (loading) => set({ loading }),

  processingData: {},
  processingHistory: {},
  processingInstanceHistory: {},

  setProcessData: (data) => set({ processingData: data }),

  setProcessingData: (data) => {
    set((state) => {
      const updatedFailureRateData = Object.entries(
        data.instance_failure_rate
      ).reduce((acc, [key, value]) => {
        acc[key] = JSON.parse(value);
        return acc;
      }, {});

      const postProcessingInstance = state.postProcessingInstanceData.reduce(
        (acc, item) => {
          const totalFailureCount = item.inServiceInstances.reduce(
            (sum, service) => {
              const failureRate =
                updatedFailureRateData[service.InstanceId]?.failure_rate || 0;
              return sum + failureRate;
            },
            0
          );

          acc[replaceKeyInString(item.asgName)] =
            totalFailureCount > 0
              ? totalFailureCount / item.inServiceInstances.length
              : totalFailureCount;
          return acc;
        },
        {}
      );

      const updatedData = Object.entries(data.data).reduce(
        (acc, [key, value]) => {
          acc[key] = Number(value.counts) || 0;
          return acc;
        },
        {}
      );

      const updatedHistory = { ...state.processingHistory };
      updatedHistory[Object.keys(updatedHistory).length] = updatedData;

      const historyOverflow = Object.keys(updatedHistory).length > 30;
      if (historyOverflow) {
        Object.keys(updatedHistory)
          .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
          .slice(0, 1)
          .forEach((key) => delete updatedHistory[key]);
      }

      const reindexedProcessingHistory = Object.values(updatedHistory).reduce(
        (acc, value, index) => {
          acc[index] = value;
          return acc;
        },
        {}
      );

      const updatedProcessingInstanceHistory = {
        ...state.processingInstanceHistory,
      };

      updatedProcessingInstanceHistory[
        Object.keys(updatedProcessingInstanceHistory).length
      ] = postProcessingInstance;

      const instanceHistoryOverflow =
        Object.keys(updatedProcessingInstanceHistory).length > 30;

      if (instanceHistoryOverflow) {
        Object.keys(updatedProcessingInstanceHistory)
          .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
          .slice(0, 1)
          .forEach((key) => delete updatedProcessingInstanceHistory[key]);
      }

      const reindexedProcessingInstanceHistory = Object.values(
        updatedProcessingInstanceHistory
      ).reduce((acc, value, index) => {
        acc[index] = value;
        return acc;
      }, {});

      const stateData =
        state?.processingData.data &&
        Object.values(state.processingData.data)
          .map((item) => item.batch_size)
          .join(",");
      const responseData =
        data?.data &&
        Object.values(data.data)
          .map((item) => item.batch_size)
          .join(",");

      const stateStatusData =
        state?.processingData.data &&
        Object.values(state.processingData.data)
          .map((item) => item.status)
          .join(",");
      const responseStatusData =
        data?.data &&
        Object.values(data.data)
          .map((item) => item.status)
          .join(",");

      if (stateData && responseData && stateData !== responseData) {
        return {
          processingData: state.processingData,
          batchNStatusLoading: false,
          processingHistory: reindexedProcessingHistory,
          processingInstanceHistory: reindexedProcessingInstanceHistory,
        };
      }

      if (
        stateStatusData &&
        responseStatusData &&
        stateStatusData !== responseStatusData
      ) {
        return {
          processingData: state.processingData,
          batchNStatusLoading: false,
          processingHistory: reindexedProcessingHistory,
          processingInstanceHistory: reindexedProcessingInstanceHistory,
        };
      }

      return {
        processingData: data,
        batchNStatusLoading: false,
        processingHistory: reindexedProcessingHistory,
        processingInstanceHistory: reindexedProcessingInstanceHistory,
      };
    });
  },

  processingLoading: false,
  setProcessingLoading: (processLoading) => set({ processLoading }),

  instanceData: {},
  setInstanceData: (data) => set({ instanceData: data }),

  instanceLoading: false,
  setInstanceLoading: (loading) => set({ instanceLoading: loading }),

  batchNStatus: {},
  setBatchNStatus: (data) => set({ batchNStatus: data }),

  batchNStatusLoading: false,
  setBatchNStatusLoading: (loading) => set({ batchNStatusLoading: loading }),
}));

export default usePostProcessingStore;
