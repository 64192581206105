const Indexed = ({ width = "30", height = "30" }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-text-quote"
      >
        <path d="M17 6H3" />
        <path d="M21 12H8" />
        <path d="M21 18H8" />
        <path d="M3 12v6" />
      </svg>
    </>
  );
};
export default Indexed;
