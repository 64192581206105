import IconsMap from '@/components/IconsMap/IconsMap';
import { formatNumber } from '@/pages/Dashboard/utils';


export default function TakedownStatCard({platform, completed, total, percentage}) {
    const getColor = (percent) => {
        const value = parseFloat(percent);
        if (value < 30) return 'bg-red-500';
        if (value >= 30 && value < 50) return 'bg-yellow-500';
        return 'bg-green-500';
    };

    const Icon = IconsMap[platform];

    return (
        <div className="bg-white border rounded-xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg flex items-center gap-2 font-semibold capitalize">
                    {Icon ? (
                        <div className="relative group">
                            <div className="w-10 h-10 flex items-center justify-center">
                                <Icon className="w-6 h-6 text-white" />
                            </div>
                        </div>
                    ) : null}
                    {platform}
                </h3>
                {/* {type === 'media' ? (
                    <CircleSlash className="w-6 h-6 text-indigo-600"/>
                ) : (
                    <AlertCircle className="w-6 h-6 text-purple-600"/>
                )} */}
            </div>

            <div className="space-y-4">
                <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                        className={`absolute top-0 left-0 h-full ${getColor(percentage)} transition-all duration-500`}
                        style={{width: percentage}}
                    />
                </div>

                <div className="flex justify-between items-center text-sm">
                    <div className="flex items-center gap-2">
                        <div className="px-2 py-1 flex items-center gap-1">
                            <span className="font-semibold">{formatNumber(completed)}</span>
                            <span className="text-xs">/ {formatNumber(total)} completed</span>
                        </div>
                    </div>
                    <span className="font-bold text-md text-gray-800">{percentage}</span>
                </div>
            </div>
        </div>
    );
}