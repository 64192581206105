import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes.js";
import API from "@/axios/axios.js";

function* fetchPostProcessingData() {
  try {
    const response = yield call(API.get, "/post-processing/stats?days=15");
    yield put({
      type: actionTypes.GET_POST_PROCESSING_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_POST_PROCESSING_DATA_FAILURE,
      error: error.message,
    });
  }
}

function* fetchProcessingData() {
  try {
    const response = yield call(API.get, "/post-processing/");
    yield put({
      type: actionTypes.PROCESSING_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.PROCESSING_DATA_FAILURE,
      error: error.message,
    });
  }
}

function* fetchPostProcessingInstanceData() {
  try {
    const response = yield call(API.get, "/post-processing/instances");
    yield put({
      type: actionTypes.GET_POST_PROCESSING_INSTANCE_DATA_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_POST_PROCESSING_INSTANCE_DATA_FAILURE,
      error: error.message,
    });
  }
}

function* updateInstanceCapacityData({ payload }) {
  try {
    const response = yield call(
      API.post,
      "/post-processing/update-instance-capacity",
      payload
    );
    yield put({
      type: actionTypes.UPDATE_INSTANCE_CAPACITY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_INSTANCE_CAPACITY_FAILURE,
      error: error.message,
    });
  }
}

function* updateBatchNStatus({ payload }) {
  try {
    const response = yield call(
      API.post,
      "/post-processing/update-batch-n-status",
      payload
    );
    yield put({
      type: actionTypes.UPDATE_BATCH_N_STATUS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_BATCH_N_STATUS_FAILURE,
      error: error.message,
    });
  }
}

export default function* postProcessingSaga() {
  yield all([
    takeLatest(actionTypes.GET_POST_PROCESSING_DATA, fetchPostProcessingData),
    takeLatest(
      actionTypes.GET_POST_PROCESSING_INSTANCE_DATA,
      fetchPostProcessingInstanceData
    ),
    takeLatest(actionTypes.PROCESSING_DATA, fetchProcessingData),
    takeLatest(
      actionTypes.UPDATE_INSTANCE_CAPACITY,
      updateInstanceCapacityData
    ),
    takeLatest(actionTypes.UPDATE_BATCH_N_STATUS, updateBatchNStatus),
  ]);
}
