import { create } from "zustand";

const useTakedownStatsStore = create((set) => ({
    takedownData: [],
    setTakedownData: (data) => set({ takedownData: data }),
    
    loading: false,
    setLoading: (loading) => set({ loading }),  
}));

export default useTakedownStatsStore;
