import { Area, AreaChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.jsx";
import { ChartContainer } from "@/components/ui/chart.jsx";
import { formatNumber } from "@/pages/Dashboard/utils.js";
import { formatDate } from "@/lib/utils.js";
import _ from "lodash/fp.js";
import IconsMap from "@/components/IconsMap/IconsMap.jsx";

const transformData = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }

  return Object.keys(data)
    .map((date) => {
      let transformedEntry = { date };

      Object.entries(data[date]).forEach(([key, value]) => {
        const updatedKey =
          key === "preprocessing search results non-review"
            ? "preprocessing search results"
            : key;
        transformedEntry[updatedKey] = value;
      });

      return transformedEntry;
    })
    .reverse();
};

const generateChartConfig = (data) => {
  if (!data || data.length === 0) {
    return {};
  }

  const predefinedColors = {
    scraped: "#FF7F7F",
    processed: "#FFDB58",
    indexed: "#32CD32 ",
  };

  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#00A2AC",
    "#ffab91",
    "#ffd966",
    "#a4de6c",
  ];

  let colorIndex = 0;

  return Object.keys(data[0])
    .filter((key) => key !== "date" && key !== "total")
    .reduce((config, key) => {
      const updatedKey =
        key === "preprocessing search results non-review"
          ? "preprocessing search results"
          : key;

      if (predefinedColors[updatedKey]) {
        config[updatedKey] = {
          label: _.upperFirst(updatedKey),
          color: predefinedColors[updatedKey],
        };
      } else {
        config[updatedKey] = {
          label: _.upperFirst(updatedKey),
          color: colors[colorIndex % colors.length],
        };
        colorIndex++;
      }
      return config;
    }, {});
};

const LegendRender = ({ payload }) => {
  return (
    <ul
      className={
        "flex flex-row gap-4 justify-center items-center text-muted-foreground pt-2"
      }
    >
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="flex items-center gap-2">
          <div
            style={{ backgroundColor: entry.color, width: 12, height: 12 }}
            className="inline-block rounded-sm"
          />
          <span className="capitalize">
            {entry.value && entry.value.replace("-", " ")}
          </span>
        </li>
      ))}
    </ul>
  );
};

const TooltipRender = ({ active, payload, hideTotal, isPercent }) => {
  if (active && payload && payload.length) {
    const sortedData = hideTotal
      ? payload.sort((a, b) => b.value - a.value)
      : payload
          .filter((entry) => entry.value > 0)
          .sort((a, b) => b.value - a.value);
    const totalValue = payload[0]?.payload?.total || 0;

    return (
      <div className="border rounded-md flex flex-col gap-2 p-4 shadow-md bg-background">
        {!hideTotal ? (
          <div className="flex gap-2 flex-row items-center">
            <p className="font-bold text-muted-foreground">Total:</p>
            <p>
              {formatNumber(totalValue)}
              {isPercent && "%"}
            </p>
          </div>
        ) : (
          ""
        )}
        {sortedData?.map((entry, index) => (
          <div
            key={`item-${index}`}
            className="flex gap-2 flex-row items-center"
          >
            {IconsMap[entry.dataKey]
              ? IconsMap[entry.dataKey]({ height: "20px", width: "20px" })
              : ""}

            <p className="capitalize" style={{ color: entry.color }}>
              {entry.dataKey && entry.dataKey.replace("-", " ")}
            </p>
            <p className={"text-muted-foreground"}>
              {formatNumber(entry.value)}
              {isPercent && "%"}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

function Charts({
  title,
  data,
  isPercent = false,
  hideTitle = false,
  postProcessingTitle,
  showNumber = false,
  loading = false,
  reversed = false,
}) {
  const mainCategories = "indexed";
  const transformedData = transformData(data);
  const chartConfig = generateChartConfig(transformedData);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-3">
          {postProcessingTitle
            ? ""
            : !hideTitle && (
                <CardTitle className="flex items-center gap-3">
                  <div>
                    {IconsMap[title]
                      ? IconsMap[title]({ height: "20px", width: "20px" })
                      : IconsMap[mainCategories]
                      ? IconsMap[mainCategories]({
                          height: "20px",
                          width: "20px",
                        })
                      : ""}
                  </div>
                  <div>{_.upperFirst(title)}</div>
                </CardTitle>
              )}
        </CardTitle>
        <CardDescription>{title}</CardDescription>
      </CardHeader>
      <CardContent className="px-2 relative">
        {loading ? (
          <div className="absolute w-[99%] h-[99%] bg-gray-100 bg-opacity-100 text-black flex items-center justify-center z-10 rounded-md">
            Loading...
          </div>
        ) : null}
        <ChartContainer
          config={chartConfig}
          className={
            postProcessingTitle
              ? "w-full h-[22rem]"
              : (hideTitle && "w-full h-[220px]") || "w-full h-[18rem]"
          }
        >
          <AreaChart data={transformedData} height={100}>
            <defs>
              {Object.keys(chartConfig).map((key) => (
                <linearGradient
                  key={key}
                  id={`color${_.upperFirst(key)}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={chartConfig[key].color}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={chartConfig[key].color}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            <XAxis
              dataKey="date"
              tickLine={true}
              axisLine={true}
              tickMargin={8}
              reversed={reversed}
              tickFormatter={(date) =>
                !showNumber ? formatDate(date, "dd MMM") : date
              }
            />
            <YAxis tickFormatter={formatNumber} />
            <Tooltip
              content={TooltipRender}
              hideTotal={hideTitle}
              isPercent={isPercent}
            />
            <Legend
              iconType={"circle"}
              formatter={_.capitalize}
              content={LegendRender}
            />
            {Object.keys(chartConfig).map((key) => (
              <Area
                key={key}
                dataKey={key}
                type="monotone"
                stroke={chartConfig[key].color}
                fill={`url(#color${_.upperFirst(key)})`}
                strokeWidth={2}
                stackId={postProcessingTitle ? "1" : null}
              />
            ))}
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

export default Charts;
