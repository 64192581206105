import _ from "lodash/fp.js";
import { cn, getMaxValue, toShortForm } from "@/lib/utils.js";
import ReactSpeedometer from "react-d3-speedometer";
import updateRateStore from "@/components/common/updateRateStore";
import { useStore } from "zustand";
import { useEffect, useState } from "react";
import { BugIcon, CpuIcon, Edit } from "lucide-react";
import IconsMap from "@/components/IconsMap/IconsMap";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import axios from "axios";
import Loader from "@/components/ui/loader";
import { Alert } from "@/components/ui/alert";

const EditDataSection = ({ name, min, max, desired, setNewCapacity }) => {
  const [currentValue, setCurrentValue] = useState(desired ?? min ?? 0);
  const [tooltipPosition, setTooltipPosition] = useState("");

  const updateCurrentValue = (value) => {
    setCurrentValue(value);
    setNewCapacity(value);
  };

  useEffect(() => {
    const percentage = ((currentValue - min) / (max - min)) * 100;
    setTooltipPosition(`calc(${percentage}%)`);
  }, [currentValue]);

  return (
    <div className="my-2">
      <p className="mb-1 text-lg">{name}: </p>
      <div className="flex justify-start gap-4"></div>
      <div className="relative w-full">
        <div
          className="absolute top-[-30px] text-sm bg-primary text-white py-1 px-2 rounded-md"
          style={{
            left: tooltipPosition,
            transform: "translateX(-50%)",
          }}
        >
          {currentValue}
        </div>
        <input
          type="range"
          min={min}
          max={max}
          value={currentValue}
          onChange={(e) => updateCurrentValue(e.target.value)}
          className="w-full h-2 bg-slate-300 rounded-lg appearance-none focus:outline-none"
        />
        <div className="flex justify-between text-sm mb-1">
          <span>{min}</span>
          <span>{max}</span>
        </div>
      </div>
    </div>
  );
};

const EditSection = ({ name }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [scraperCount, setScraperCount] = useState();
  const [videoProcessorCount, setVideoProcessorCount] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateCount = async () => {
    try {
      if (videoProcessorCount < 0 || scraperCount < 0) {
        setError("Scraper Count and Processor count should be postive.");
        return;
      }
      setLoading(true);
      const requestBody = {
        category: name.toLowerCase(),
      };
      if (scraperCount >= 0) {
        requestBody.scraper = { desiredCapacity: scraperCount.toString() };
      }
      if (videoProcessorCount >= 0) {
        requestBody.videoProcessor = {
          desiredCapacity: videoProcessorCount.toString(),
        };
      }
      const { data } = await axios.post(
        "https://kzp44c4bn5nlhxzdi4egin7iqy0kwyta.lambda-url.us-east-1.on.aws/updateCategory",
        requestBody
      );
      if (data.success) {
        setError(false);
        await getExistingAsg();
        setVideoProcessorCount(null);
        setScraperCount(null);
      }
    } catch (error) {
      console.log(error);
      setError("Unable to upadate Capacity");
    } finally {
      setLoading(false);
    }
  };

  const getExistingAsg = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://kzp44c4bn5nlhxzdi4egin7iqy0kwyta.lambda-url.us-east-1.on.aws/category?category=${name.toLowerCase()}`
      );
      setData(data);
      setError(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getExistingAsg().then(() => {});
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <div className={"flex justify-end items-end "}>
          <Button variant="ghost" className={""}>
            <Edit />
          </Button>
        </div>
      </DialogTrigger>

      <DialogContent>
        {loading ? (
          <div className="flex justify-center">
            <Loader lg />
          </div>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle className={"text-2xl"}>
                {_.capitalize(name)}
              </DialogTitle>
            </DialogHeader>
            {error && <Alert variant="error">{error}</Alert>}

            <EditDataSection
              name="Scrapers"
              min={data?.scraper?.minCapacity}
              max={data?.scraper?.maxCapacity}
              current={data?.scraper?.instancesInService}
              desired={data?.scraper?.desiredCapacity}
              newCapacity={scraperCount}
              setNewCapacity={setScraperCount}
            />
            {data?.videoProcessor && (
              <EditDataSection
                name="Video Processors"
                min={data?.videoProcessor?.minCapacity}
                max={data?.videoProcessor?.maxCapacity}
                current={data?.videoProcessor?.instancesInService}
                desired={data?.videoProcessor?.desiredCapacity}
                newCapacity={videoProcessorCount}
                setNewCapacity={setVideoProcessorCount}
              />
            )}
            <DialogFooter>
              <button
                className="px-4 py-2 bg-green-600 text-white rounded-md"
                onClick={updateCount}
              >
                Confirm
              </button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const SpeedOMeterGrid = ({ speedometerData }) => {
  return (
    <div className={cn("w-full gap-3 place-items-center", "grid grid-cols-4")}>
      {speedometerData.map(
        (
          {
            name,
            maxValue,
            value,
            valueText,
            valueColor,
            scraperRate,
            processorRate,
          },
          index
        ) => {
          if (name === "live") return null;

          const Icon = IconsMap[name.toLowerCase()] || null;
          return (
            <Card key={index} className={"w-full flex flex-col gap-3"}>
              <EditSection name={name} />
              <CardContent className={cn("w-full flex m-0 p-0", "h-[160px]")}>
                <ReactSpeedometer
                  fluidWidth={true}
                  forceRender={true}
                  ringWidth={20}
                  needleHeightRatio={0.5}
                  maxValue={maxValue}
                  value={value}
                  segments={20}
                  maxSegmentLabels={0}
                  needleTransitionDuration={0}
                  valueFormat={"0.2s"}
                  currentValueText={valueText}
                  valueTextFontSize={"14px"}
                  valueTextFontWeight={"bold"}
                  textColor={valueColor}
                />
              </CardContent>

              <CardFooter>
                <div className={"flex items-center gap-3 ml-1"}>
                  {Icon && <Icon />}
                  <div className="w-[150px] gap-1 flex flex-col">
                    <div className={"flex flex-row gap-2 items-center"}>
                      <BugIcon className={"w-4 h-4"} />
                      <p className={"text-primary text-sm"}>
                        {toShortForm(scraperRate)} /min
                      </p>
                    </div>
                    <div className={"flex flex-row gap-2 items-center"}>
                      <CpuIcon className={"w-4 h-4"} />
                      <p className={"text-primary text-sm"}>
                        {toShortForm(processorRate)} /min
                      </p>
                    </div>
                  </div>
                </div>
              </CardFooter>
            </Card>
          );
        }
      )}
    </div>
  );
};

export function SpeedOMeterSection() {
  const { data: rateData } = useStore(updateRateStore);

  const [speedometerData, setSpeedometerData] = useState([]);
  const [totalSpeedometerData, setTotalSpeedometerData] = useState({});

  useEffect(() => {
    const keys = _.keys(rateData);
    const groupedKeys = _.groupBy((key) => key.split("_")[1], keys);
    const platforms = _.keys(groupedKeys);

    const data = platforms.map((platform) => {
      const processorRate =
        rateData[`processors_${platform}`]?.current_rate || 0;
      const scraperRate = rateData[`scrapers_${platform}`]?.current_rate || 0;

      const maxValue = getMaxValue(processorRate, scraperRate);

      const diffValue = processorRate - scraperRate;

      const valueText =
        diffValue === 0
          ? "0"
          : diffValue < 0
          ? `-${toShortForm(Math.abs(diffValue))}`
          : `+${toShortForm(diffValue)}`;
      const valueColor =
        diffValue === 0 ? "#000" : diffValue < 0 ? "#dc2626" : "#16a34a";

      return {
        name: platform,
        scraperRate,
        processorRate,
        maxValue: maxValue > 0 ? maxValue : 1,
        value: processorRate,
        maxValueLabel: scraperRate,
        valueText,
        valueColor,
      };
    });
    setSpeedometerData(data);
  }, [rateData]);

  useEffect(() => {
    if (!speedometerData || speedometerData.length === 0) return;

    const total = speedometerData.find(({ name }) => name === "live");
    setTotalSpeedometerData(total);
  }, [speedometerData]);

  return (
    <div className={"flex flex-row gap-3"}>
      <div className={"flex w-[25%] h-full"}>
        <Card className={"flex flex-col w-full h-full"}>
          <CardHeader className={"w-full"}>
            <CardTitle className={"text-xl text-gray-700"}>
              Ingestors vs Processors
            </CardTitle>
          </CardHeader>
          <CardContent
            className={"flex flex-col w-full h-[318px] items-center"}
          >
            <ReactSpeedometer
              fluidWidth={true}
              forceRender={true}
              ringWidth={20}
              needleHeightRatio={0.7}
              maxValue={totalSpeedometerData?.maxValue || 1}
              value={totalSpeedometerData?.value || 0}
              segments={20}
              maxSegmentLabels={0}
              needleTransitionDuration={0}
              currentValueText={""}
            />
          </CardContent>
          <CardFooter className={"flex flex-col w-full gap-5"}>
            <p
              className={"text-3xl font-bold mb-9"}
              style={{
                color: totalSpeedometerData?.valueColor,
              }}
            >
              {totalSpeedometerData?.valueText}
            </p>

            <div className={"flex gap-3 w-full mt-5"}>
              <div className="gap-1 flex flex-row justify-between w-full">
                <div className={"flex flex-col gap-2"}>
                  <BugIcon className={"w-6 h-6 text-gray-600"} />
                  <p className={"text-gray-600 text-xl w-40 font-bold"}>
                    {toShortForm(totalSpeedometerData?.scraperRate)} /min
                  </p>
                </div>
                <div className={"flex flex-col gap-2"}>
                  <CpuIcon className={"w-6 h-6 text-gray-600"} />
                  <p className={"text-gray-600 text-xl w-40 font-bold"}>
                    {toShortForm(totalSpeedometerData?.processorRate)} /min
                  </p>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
      <div className={"w-full"}>
        <SpeedOMeterGrid speedometerData={speedometerData} />
      </div>
    </div>
  );
}
